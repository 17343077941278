import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';
import { StaticImage } from 'gatsby-plugin-image';

import * as Base from 'src/page-styles/base.styled';
import BlueprintSrc from 'src/images/services/blueprint.png';

import Layout from 'src/Layout';

import PreflightHead from 'src/Components/ScrollAnims/PreflightHead';
import LetsChat from 'src/Components/ServiceComponents/LetsChat';
import {
  BlueprintBg,
  Columns,
  LinkWrapper,
  Title,
  Paragraph,
} from 'src/Components/ServiceComponents/Options';
import SEO from 'src/Components/SEO';

const HeadingAnim = keyframes`
  0% {
    transform: translate(-50%, -200%);
  }

  100% {
    transform: translate(-50%, 0);
  }
`;

const HeadingWrapper = styled.div`
  animation: 1s ${HeadingAnim};

  color: white;

  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 1.75rem;
  line-height: 2.25rem;
  text-align: center;

  position: absolute;

  left: 50%;
  top: 20%;
  transform: translate(-50%, 0);

  z-index: 2;

  @media only screen and (min-width: 768px) {
    font-size: 3.5rem;
    line-height: 4rem;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 3.5rem;
    line-height: 4rem;
  }

  @media only screen and (min-width: 1440px) {
    font-size: 4rem;
    line-height: 4.5rem;
  }

  @media only screen and (min-width: 1800px) {
    font-size: 5rem;
    line-height: 5.5rem;
  }
`;

const LeftAnim = keyframes`
  0% {
    transform: translateX(-150%);
  }

  100% {
    transform: translateX(0);
  }
`;

const LeftContentWrapper = styled.div`
  background: ${({ theme }) => theme.grey200};
  font-family: ${({ theme }) => theme.fontBody};
  font-size: 16px;
  color: #223e74;

  padding: 1rem;

  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);

  z-index: 3;

  width: 300px;

  @media only screen and (min-width: 768px) {
    font-size: 22px;
    width: 600px;
  }

  @media only screen and (min-width: 1024px) {
    animation: 1.5s ${LeftAnim} 0.5s ease-in-out backwards;
    font-size: 20px;
    width: 400px;

    left: 64px;
    top: 50%;
    transform: translate(0, 0);
  }

  @media only screen and (min-width: 1280px) {
    font-size: 24px;
    padding: 2rem;
  }

  @media only screen and (min-width: 1440px) {
    font-size: 32px;
  }

  @media only screen and (min-width: 1440px) {
    width: 500px;
    top: 60%;
  }

  @media only screen and (min-width: 1800px) {
    width: 700px;
    top: 50%;
  }
`;

const RightAnim = keyframes`
  0% {
    transform: translateX(150%);
  }

  100% {
    transform: translateX(0);
  }
`;

const RightContentWrapper = styled.div`
  background: ${({ theme }) => theme.grey200};
  font-family: ${({ theme }) => theme.fontBody};
  font-size: 16px;
  color: #223e74;

  padding: 1rem;

  position: absolute;
  right: 50%;
  top: 80%;
  transform: translate(50%, -50%);

  z-index: 3;

  width: 300px;

  @media only screen and (min-width: 768px) {
    font-size: 22px;
    width: 600px;
  }

  @media only screen and (min-width: 1024px) {
    animation: 1.5s ${RightAnim} 1s ease-in-out backwards;
    font-size: 20px;
    width: 400px;

    right: 64px;
    top: 50%;
    transform: translate(0, 0);

    right: 64px;
    top: 50%;
  }

  @media only screen and (min-width: 1280px) {
    font-size: 24px;
    padding: 2rem;
  }

  @media only screen and (min-width: 1440px) {
    font-size: 32px;
  }

  @media only screen and (min-width: 1440px) {
    width: 500px;
  }

  @media only screen and (min-width: 1800px) {
    width: 700px;
  }
`;

const HeadInfo = styled.div`
  background: url(${BlueprintSrc});
  position: relative;
  height: 100vh;
  overflow: hidden;
`;

function PreFlight(): JSX.Element {
  return (
    <>
      <SEO
        title="Preflight services | Saber Astronautics"
        description="There's a lot to do before launch, Saber has the connections and the expertise to get you safely to the launch pad."
        url="https://www.saberastro.com/pre-flight"
      />
      <Layout>
        <HeadInfo>
          <HeadingWrapper>
            Time to prepare
            <br />
            <Base.Yellow>your</Base.Yellow> mission.
          </HeadingWrapper>
          <LeftContentWrapper>
            Preflight integration is the foundation for your successful flight
          </LeftContentWrapper>
          <RightContentWrapper>
            The RSOC uses common standards, cloud services, and software APIs to
            get your spacecraft to flight. Saber’s experienced flight team will
            work with you to ensure a smooth integration of your mission at all
            phases of development.
          </RightContentWrapper>
        </HeadInfo>
        <PreflightHead />
        <BlueprintBg>
          <Columns>
            <LinkWrapper>
              <Link to="/mission-design">
                <Title>
                  Need a design? <FaRegArrowAltCircleRight />
                </Title>
                <Paragraph>
                  Take a look at our Mission Design services.
                </Paragraph>
              </Link>
            </LinkWrapper>
            <LinkWrapper>
              <Link to="/diagnostics">
                <Title>
                  Diagnostics <FaRegArrowAltCircleRight />
                </Title>
                <Paragraph style={{ margin: 0 }}>
                  Take a look at our Diagnostics services.
                </Paragraph>
              </Link>
            </LinkWrapper>
            <LinkWrapper>
              <Link to="/operations">
                <Title>
                  In Orbit? <FaRegArrowAltCircleRight />
                </Title>
                <Paragraph>
                  Check out our flight and diagnostic services.
                </Paragraph>
              </Link>
            </LinkWrapper>
          </Columns>
        </BlueprintBg>
        <LetsChat
          image={
            <StaticImage
              className="background-image"
              src="../images/letschat2.jpg"
              alt="Rocket launching off"
            />
          }
        />
      </Layout>
    </>
  );
}

export default PreFlight;
