import React from 'react';
import styled from 'styled-components';

const Text = styled.div`
  color: #223e74;
  font-family: ${({ theme }) => theme.fontBody};
  font-size: 15px;
  text-align: center;

  position: absolute;
  left: 50%;
  bottom: 20%;
  transform: translateX(-50%);

  max-width: 300px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    bottom: 15%;
    font-size: 20px;
    max-width: 700px;
  }

  @media only screen and (min-width: 1024px) {
    max-width: 768px;
  }

  @media only screen and (min-width: 1280px) {
    max-width: 1100px;
    font-size: 24px;
  }

  @media only screen and (min-width: 1440px) {
    max-width: 1300px;
  }
`;

interface Usefulness {
  progress: number;
}

export default React.memo(({ progress }: Usefulness) => {
  const clipLeft = 50 - progress / 2;
  const clipRight = 50 + progress / 2;

  return (
    <Text
      style={{
        clipPath: `polygon(${clipLeft}% 0%, ${clipRight}% 0%, ${clipRight}% 100%, ${clipLeft}% 100%)`,
      }}
    >
      Saber will also support command-loop testing with your satellite for
      confidence in the final solutions before flight.
    </Text>
  );
});
