import React from 'react';
import styled from 'styled-components';

const Dropbar = styled.div`
  background: #223e74;

  position: absolute;
  left: 50%;
  width: 4px;
  transform: translateX(-50%);

  z-index: 5;

  &.hidden {
    display: none;
  }
`;

interface Components {
  progress: number;
}

const DropbarComponent = React.memo(({ progress }: Components) => {
  const height = progress;
  const hidden = height >= 100;

  return (
    <Dropbar
      className={hidden ? 'hidden' : ''}
      style={{ height: `${height}%` }}
    />
  );
});

export default DropbarComponent;
