import React from 'react';
import styled from 'styled-components';

import LeftSrc from 'src/images/services/satelliteleft.png';
import RightSrc from 'src/images/services/satelliteright.png';

const LeftBar = styled.div`
  background: #223e74;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  height: 100%;
  width: 4px;

  z-index: 5;

  &.hidden {
    display: none;
  }
`;

const RightBar = styled.div`
  background: #223e74;

  position: absolute;
  right: 50%;
  transform: translateX(50%);

  height: 100%;
  width: 4px;

  z-index: 5;

  &.hidden {
    display: none;
  }
`;

const Image = styled.div`
  position: absolute;
  top: 50%;

  height: 150px;
  width: 150px;

  @media only screen and (min-width: 768px) {
    height: 250px;
    width: 250px;
  }

  @media only screen and (min-width: 1024px) {
    height: 300px;
    width: 300px;
  }

  @media only screen and (min-width: 1280px) {
    height: 400px;
    width: 400px;
  }

  @media only screen and (min-width: 1440px) {
    height: 500px;
    width: 500px;
  }
`;

const LeftImage = styled(Image)`
  background: url(${LeftSrc});
  background-size: cover;

  left: 50%;
  transform: translate(-50%, -40%);
`;

const RightImage = styled(Image)`
  background: url(${RightSrc});
  background-size: cover;

  right: 50%;
  transform: translate(50%, -40%);
`;

interface Images {
  progress: number;
}

const Images = React.memo(({ progress }: Images) => {
  const barPosition = 50 - progress / 2;
  const barHidden = progress <= 0;

  const imagePosition = Math.max(50 - progress, 25);

  const leftImageClip = Math.min(50 + progress * 3.4, 100);
  const rightImageClip = Math.max(50 - progress * 3.4, 0);

  return (
    <>
      <LeftBar
        className={barHidden ? 'hidden' : ''}
        style={{ left: `${barPosition}%` }}
      />
      <RightBar
        className={barHidden ? 'hidden' : ''}
        style={{ right: `${barPosition}%` }}
      />
      <LeftImage
        style={{
          clipPath: `polygon(0% 0%, ${leftImageClip}% 0%, ${leftImageClip}% 100%, 0% 100%)`,
          left: `${imagePosition}%`,
        }}
      />
      <RightImage
        style={{
          clipPath: `polygon(${rightImageClip}% 0%, 100% 0%, 100% 100%, ${rightImageClip}% 100%)`,
          right: `${imagePosition}%`,
        }}
      />
    </>
  );
});

export default Images;
