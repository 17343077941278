import React, { useRef } from 'react';
import styled from 'styled-components';
import { useScrollProgress } from 'src/Utils/hooks';

import Dropbar from './Dropbar';
import Images from './Images';
import Usefulness from './Usefulness';
import VirtualModelling from './VirtualModelling';
import Indicator from '../Indicator';

const Scroll = styled.div`
  position: sticky;
  left: 0;
  top: 0;

  overflow: hidden;

  height: 100vh;
  width: 100%;
`;

const ScrollWrapper = styled.div`
  background: ${({ theme }) => theme.grey200};
  position: relative;
  height: 200vh;
`;

function PreflightHead(): JSX.Element {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const scroll = useScrollProgress(containerRef);

  const dropbarProgress = (10 / 3) * Math.min(scroll, 30);
  const imageProgress = 100 * (Math.max(scroll - 30, 0) / 60);

  return (
    <ScrollWrapper ref={containerRef}>
      <Scroll>
        <Dropbar progress={dropbarProgress} />
        <Images progress={imageProgress} />
        <VirtualModelling progress={imageProgress} />
        <Usefulness progress={imageProgress} />
        <Indicator black hidden={scroll >= 80} />
      </Scroll>
    </ScrollWrapper>
  );
}

export default PreflightHead;
