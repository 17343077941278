import React from 'react';
import styled from 'styled-components';

const Text = styled.div`
  color: #223e74;
  font-family: ${({ theme }) => theme.fontBody};
  font-size: 12px;

  position: absolute;
  left: 50%;
  top: 15%;
  transform: translateX(-50%);

  max-width: 300px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    font-size: 14px;
    max-width: 700px;
  }

  @media only screen and (min-width: 1024px) {
    font-size: 16px;
    max-width: 900px;
  }

  @media only screen and (min-width: 1280px) {
    font-size: 16px;
    max-width: 1100px;
  }

  @media only screen and (min-width: 1440px) {
    font-size: 18px;
    max-width: 1300px;
  }
`;

interface VirtualModelling {
  progress: number;
}

const VirtualModelling = React.memo(({ progress }: VirtualModelling) => {
  const clipLeft = 50 - progress / 2;
  const clipRight = 50 + progress / 2;

  return (
    <Text
      style={{
        clipPath: `polygon(${clipLeft}% 0%, ${clipRight}% 0%, ${clipRight}% 100%, ${clipLeft}% 100%)`,
      }}
    >
      As part of preflight preparation, Saber Astronautics works with your
      flight software and spacecraft manufacturing teams to ensure proper
      integration of command, data handling, and missions states.
      <br />
      <br />
      Using the Enterprise P.I.G.I. suite, we will visually represent aspects of
      your satellite engineering in the operations floor for proper “drill down”
      during testing.
    </Text>
  );
});

export default VirtualModelling;
