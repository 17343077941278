import React, { useEffect, useState } from 'react';

export function useScrollProgress(
  containerRef: React.MutableRefObject<HTMLDivElement | null>,
): number {
  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    if (!containerRef) {
      return;
    }

    function onScroll(event: Event) {
      event.preventDefault();
      event.stopPropagation();
      const containerTop = containerRef.current?.offsetTop;
      const containerHeight = containerRef.current?.offsetHeight;
      const currentPosition = window.pageYOffset;
      const windowHeight = window.innerHeight;

      if (containerTop === undefined || containerHeight === undefined) {
        return;
      }

      const progress =
        ((currentPosition - containerTop) / (containerHeight - windowHeight)) *
        100;

      if (progress < 0) {
        setScrollProgress(0);
      } else if (progress > 100) {
        setScrollProgress(100);
      } else {
        setScrollProgress(progress);
      }
    }

    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return scrollProgress;
}
