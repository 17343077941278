import React from 'react';
import styled, { keyframes } from 'styled-components';

import { HiChevronDoubleDown } from 'react-icons/hi';

const ArrowAnim = keyframes`
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(50%);
  }

  100% {
    transform: translateY(0);
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  left: 50%;
  bottom: 5%;
  transform: translateX(-50%);
  transition: all 500ms;

  visibility: hidden;
  z-index: 5;

  @media only screen and (min-width: 1200px) {
    visibility: visible;
  }
`;

const Text = styled.div`
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 1rem;
  white-space: nowrap;

  @media only screen and (min-width: 768px) {
    font-size: 1.125rem;
  }
`;

const Arrow = styled.div`
  font-size: 2rem;
  animation: ${ArrowAnim} 2s linear infinite;
`;

interface Props {
  black?: boolean;
  hidden?: boolean;
}

export default React.memo(function ({ black, hidden }: Props) {
  return (
    <Wrapper
      style={{
        color: black ? 'black' : 'white',
        opacity: hidden ? '0' : '1',
      }}
    >
      <Text>Scroll Down</Text>
      <Arrow>
        <HiChevronDoubleDown />
      </Arrow>
    </Wrapper>
  );
});
